import dd from 'gdt-jsapi'
import { callBackInterval, iOSDistanceFilter, targetAccuracy } from '@/config'
import { Toast } from 'vant'

/**
 * @description /获取设备初始定位点
 * @param callback
 * @returns object
 */
export function getGeolocation(callback) {
  dd.ready(function() {
    dd.getGeolocation({
      targetAccuracy: 100,
      coordinate: 0,
      withReGeocode: false,
      useCache: true, //默认是true，如果需要频繁获取地理位置，请设置false
    })
      .then((res) => {
        callback(res)
      })
      .catch((err) => {
        Toast('初始定位启动失败，请退出重试')
      })
  })
}

/**
 * @description /获取设备实时定位
 * @param sceneId
 * @param callback
 * @returns object
 */
export function startGeolocation(sceneId, callback) {
  dd.ready(function() {
    dd.startGeolocation({
      // 期望定位精度半径(单位米)
      targetAccuracy: targetAccuracy,
      // iOS端位置变更敏感度，单位为m，此值会影响iOS端callback回调速率
      iOSDistanceFilter: iOSDistanceFilter,
      useCache: true,
      // 是否需要带有逆地理编码信息；
      withReGeocode: false,
      // 数据回传最小时间间隔，单位ms
      callBackInterval: callBackInterval,
      sceneId: sceneId,
      onSuccess: async (geoResult) => {
        callback(geoResult)
      },
      onFail: (error) => {
        Toast('请开启定位服务！')
      },
    })
      .then(async (res) => {
        Toast('实时定位开启')
      })
      .catch((err) => {
        Toast('实时定位启动失败，请退出重试')
      })
  })
}

/**
 * @description /关闭指定sceneId的实时定位
 * @param sceneId
 */
export function stopGeolocation(sceneId) {
  dd.ready(function() {
    dd.stopGeolocation({
      sceneId: sceneId,
    })
      .then((res) => {
        Toast('实时定位关闭')
      })
      .catch((err) => {})
  })
}
