<template>
  <div>
    <baidu-map-view ref="baiduMap" :map-data="mapData" />
    <div style="font-size: 20px; padding: 10px 16px">重点店铺二维码设置</div>
    <van-field label="店铺名称" :value="shop_Name" readonly />
    <van-field v-if="isBind" label="所属网格" :value="cell_Name" readonly />
    <team-cell
      v-if="!isBind"
      ref="cell"
      :team-id="team_ID"
      :cell-id="cell_ID"
      @teamCellChange="teamCellChange"
    />
    <van-button v-if="!isBind" color="rgb(44, 167, 234)" block @click="addBox">
      设置当前重点二维码
    </van-button>
    <van-button v-if="isBind" color="#ccc" block @click="delBox">
      取消当前重点二维码
    </van-button>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Toast, Dialog } from 'vant'
  import TeamCell from '@/components/TeamCell.vue'
  import BaiduMapView from '@/components/BaiduMapView.vue'
  import {
    Box_BD,
    Add_Box,
    Del_Box,
  } from '@/api/workBench/attendance/attendance'
  import {
    getGeolocation,
    startGeolocation,
    stopGeolocation,
  } from '@/util/jsApi'
  import { getTeamCell } from '@/api/base/base'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ShopQR',
    components: { TeamCell, BaiduMapView },
    data() {
      return {
        street_ID: null,
        street_Name: '',
        shop_ID: null,
        shop_Name: '当前地址暂时未绑定店铺',
        mapData: {
          data: [
            { lng: 0, lat: 0 },
            { lng: 0, lat: 0 },
          ],
          mapType: 3,
          showButton: false,
          height: '300px',
          preRender: true,
        },
        cell_ID: null,
        cell_Name: '',
        person_ID: null,
        team_ID: null,
        grid: [],
        isBind: false,
      }
    },
    async created() {
      this.street_ID = this.$route.query.street_ID
      this.shop_ID = Base64.decode(this.$route.query.shopID)
      this.shop_Name = Base64.decode(this.$route.query.shopName)
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.cell_ID = parseInt(teamInfo.Cell_ID)
      this.person_ID = parseInt(userInfo.User_ID)
      this.team_ID = parseInt(teamInfo.Team_ID)
      await this.initLocation()
      await this.setShopInfo()
      await this.startGeolocation()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopQR',
        '城管-重点店铺设置',
        'zzdcg.yy.gov.cn/shopQR'
      )
    },
    destroyed() {
      stopGeolocation('shopQR')
    },
    methods: {
      //初始获取当前位置坐标
      initLocation() {
        let that = this
        getGeolocation(function(res) {
          let point = that.$refs['baiduMap'].Convert_GCJ02_To_BD09({
            lng: res.longitude,
            lat: res.latitude,
          })
          that.mapData.data[0].lat = point.lat
          that.mapData.data[0].lng = point.lng
          that.$refs['baiduMap'].render()
        })
      },

      //初始化获取店铺点位
      async setShopInfo() {
        const { data } = await Box_BD({ Street_ID: this.street_ID })
        if (data.code === '1') {
          this.isBind = true
          this.mapData.data[1].lat = parseFloat(data.dplat)
          this.mapData.data[1].lng = parseFloat(data.dplon)
          this.cell_ID = parseInt(data.WG_ID)
          await this.getCellName(this.cell_ID)
        } else {
          this.isBind = false
        }
        this.$refs['baiduMap'].render()
      },

      //开始实时获取定位点
      startGeolocation() {
        let that = this
        startGeolocation('shopQR', function(res) {
          let point = that.$refs['baiduMap'].Convert_GCJ02_To_BD09({
            lng: res.longitude,
            lat: res.latitude,
          })
          that.mapData.data[0].lat = point.lat
          that.mapData.data[0].lng = point.lng
          that.$refs['baiduMap'].render()
          Toast('实时定位成功')
        })
      },

      //设置重点店铺
      async addBox() {
        Dialog.confirm({
          message: '您确定要将当前地址二维码设置为重点店铺二维码吗？',
        })
          .then(async () => {
            Toast.loading({
              duration: 3000, // 持续展示 toast
              message: '正在操作中...',
              forbidClick: true,
            })
            // on confirm
            const { data } = await Add_Box({
              Street_ID: this.street_ID,
              Person_ID: this.person_ID,
              Coordinate:
                this.mapData.data[0].lng + ',' + this.mapData.data[0].lat,
              Cell_ID: this.cell_ID,
            })
            if (data.MSG === '1') {
              await this.setShopInfo()
              Toast('操作成功')
              this.isBind = true
            } else {
              Toast('操作失败')
            }
            Toast.clear()
          })
          .catch(() => {})
      },

      //取消重点店铺
      async delBox() {
        Dialog.confirm({
          message: '确定要取消当前重点二维码地址的设置吗？',
        })
          .then(async () => {
            Toast.loading({
              duration: 3000, // 持续展示 toast
              message: '正在操作中...',
              forbidClick: true,
            })
            // on confirm
            const { data } = await Del_Box({ Street_ID: this.street_ID })
            Toast(data.MSG === '1' ? '操作成功' : '操作失败')
            if (data.MSG === '1') {
              await this.setShopInfo()
              this.isBind = false
              this.mapData.data[1].lat = 0
              this.mapData.data[1].lng = 0
            }
            Toast.clear()
          })
          .catch(() => {})
      },

      //中队选择
      teamCellChange(value) {
        this.cell_ID = parseInt(value)
      },

      //获取网格名称
      async getCellName(cellId) {
        const { data } = await getTeamCell({ Team_ID: this.team_ID })
        let teamCellOptions = data
        teamCellOptions.forEach((option) => {
          if (option.value == cellId) this.cell_Name = option.text
        })
      },
    },
  }
</script>

<style scoped></style>
