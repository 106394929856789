import request from '@/util/request'

export function getDpDZxx(params) {
  return request({
    url: '/Attendance.asmx/getDpDZxx',
    method: 'GET',
    params,
  })
}

export function DDID_ZDDP(params) {
  return request({
    url: '/Attendance.asmx/DDID_ZDDP',
    method: 'GET',
    params,
  })
}

export function Shop_DK(params) {
  return request({
    url: '/Attendance.asmx/Shop_DK',
    method: 'GET',
    params,
  })
}

export function Box_BD(params) {
  return request({
    url: '/Attendance.asmx/Box_BD',
    method: 'GET',
    params,
  })
}

export function Add_Box(params) {
  return request({
    url: '/Attendance.asmx/Add_Box',
    method: 'GET',
    params,
  })
}

export function Del_Box(params) {
  return request({
    url: '/Attendance.asmx/Del_Box',
    method: 'GET',
    params,
  })
}

export function ZDBox_BD(params) {
  return request({
    url: '/Attendance.asmx/ZDBox_BD',
    method: 'GET',
    params,
  })
}

export function Add_ZDBox(params) {
  return request({
    url: '/Attendance.asmx/Add_ZDBox',
    method: 'GET',
    params,
  })
}

export function Del_ZDBox(params) {
  return request({
    url: '/Attendance.asmx/Del_ZDBox',
    method: 'GET',
    params,
  })
}

export function selectGrid(params) {
  return request({
    url: '/Attendance.asmx/selectGrid',
    method: 'GET',
    params,
  })
}
